// @flow
import styled, { css } from "styled-components";
import { fontSize, mediaQueries, colors } from "components/styles/variables";
import { UtilsClasses } from "./utils";

const Title = css`
  line-height: 1.35;
  font-weight: inherit;
  margin-bottom: 0.75em;
  color: inherit;
`;

export const H1 = styled.h1`
  ${Title}
  font-size: ${fontSize.h1 * 0.8}px;
  font-weight: bold;

  ${mediaQueries.smMin`
    font-size: ${fontSize.h1}px;
  `}
`;

export const H2 = styled.h2`
  ${Title}
  font-size: ${fontSize.h2}px;
  font-weight: bold;
`;

export const H3 = styled.h3`
  ${Title}
  font-size: ${fontSize.h3}px;
  font-weight: bold;
`;

export const H4 = styled.h4`
  ${Title}
  font-size: ${fontSize.h4}px;
  font-weight: bold;
`;

export const H5 = styled.h5`
  ${Title}
  font-size: ${fontSize.h5}px;
  font-weight: bold;

  ${props => UtilsClasses(props)}
`;

export const H6 = styled.h6`
  ${Title}
  font-size: ${fontSize.h6}px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;

  ${props => UtilsClasses(props)}
`;

export const Lead = styled.p`
  font-size: ${fontSize.base * 1.35}px;
`;

export const Small = styled.p`
  font-size: ${fontSize.base * 0.9}px;
  line-height: 1.35;

  ${props => UtilsClasses(props)}
`;

export const Em = styled.em`
  color: ${colors.primary};
`;

export const Caption = styled(Small)`
  color: ${colors.grayDark};
`;
