// @flow
import styled, { css } from "styled-components";
import { mediaQueries } from "./variables";

type UtilsClassesType = {
  marginNone?: boolean,
  marginNoneSm?: boolean,
  textCenter?: boolean,
  textCenterSm?: boolean,
  textRight?: boolean,
  textRightSm?: boolean,
};

export const UtilsStyles = {
  marginNone: css`
    margin: 0;
  `,
  marginNoneSm: mediaQueries.smMin`margin: 0;`,
  textCenter: css`
    text-align: center;
  `,
  textCenterSm: mediaQueries.smMin`text-align: center;`,
  textRight: css`
    text-align: right;
  `,
  textRightSm: mediaQueries.smMin`text-align: right;`,
  srOnly: css`
    position: absolute;
    clip: 0;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;
  `,
};

export const UtilsClasses = (props: UtilsClassesType) => {
  let styles = [];
  Object.keys(props).map(key => {
    if (UtilsStyles[key]) {
      styles.push(UtilsStyles[key]);
    }
  });
  // Join inner arrays
  styles = styles.map(style => style.join(""));
  // Output final string
  return styles.join("");
};

export const UtilDiv = styled.div`
  ${props => UtilsClasses(props)}
`;
