// @flow
import colorShifter from "components/utils/colorShifter";
import { css } from "styled-components";

export const colors = {
  grayLight: "#F6F6F6",
  gray: "#cbcbcb",
  grayDark: "#9b9b9b",
  black: "#1D1D1D",
  blackLight: "#615959",
  blue: "#4852e8",
  blueLight: colorShifter("#4852e8", 30),
  yellow: "#D28F40",
  yellowLight: colorShifter("#fecb40", 30),
  pink: "#f7507c",
  pinkLight: colorShifter("#f7507c", 30),
  teal: "#47cdae",
  darkblue: "#1b143d",
  white: "#ffffff",
  red: "#ec302d",
  redLight: "#f59594",
  redDark: "#bb201d",
  primary: "",
  link: "",
  linkHover: "",
};

colors.primary = colors.red;
colors.link = colors.blue;
colors.linkHover = colors.blueLight;

export const fontSize = {
  h1: 54,
  h2: 32,
  h3: 24,
  h4: 20,
  h5: 16,
  h6: 14,
  base: 16,
};

export const borderRadius = {
  small: 3,
  base: 6,
};

export const boxShadow = {
  base: "0 2px 4px 0 rgba(0,0,0,0.20)",
};

export const fonts = {
  base: '"europa", -apple-system, sans-serif',
};

export const spacing = {
  base: 20,
  xsmall: 0,
  small: 0,
  medium: 0,
  large: 0,
  xlarge: 0,
};

spacing.xsmall = spacing.base * 0.5;
spacing.small = spacing.base * 0.75;
spacing.medium = spacing.base;
spacing.large = spacing.base * 1.5;
spacing.xlarge = spacing.base * 2;

export const breakPoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xsMax: 0,
  smMax: 0,
  mdMax: 0,
};

breakPoints.xsMax = breakPoints.sm - 1;
breakPoints.smMax = breakPoints.md - 1;
breakPoints.mdMax = breakPoints.lg - 1;

export const mediaQueries = {
  xsOnly: (...args) => css`
    @media only screen and (max-width: ${breakPoints.xsMax}px) {
      ${css(...args)}
    }
  `,
  smMax: (...args) => css`
    @media only screen and (max-width: ${breakPoints.smMax}px) {
      ${css(...args)}
    }
  `,
  smOnly: (...args) => css`
    @media only screen and (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.smMax}px) {
      ${css(...args)}
    }
  `,
  smMin: (...args) => css`
    @media only screen and (min-width: ${breakPoints.sm}px) {
      ${css(...args)}
    }
  `,
  mdMax: (...args) => css`
    @media only screen and (max-width: ${breakPoints.mdMax}px) {
      ${css(...args)}
    }
  `,
  mdOnly: (...args) => css`
    @media only screen and (min-width: ${breakPoints.md}px) and (max-width: ${breakPoints.mdMax}px) {
      ${css(...args)}
    }
  `,
  mdMin: (...args) => css`
    @media only screen and (min-width: ${breakPoints.md}px) {
      ${css(...args)}
    }
  `,
  lgOnly: (...args) => css`
    @media only screen and (min-width: ${breakPoints.lg}px) {
      ${css(...args)}
    }
  `,
};
