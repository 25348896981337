// @flow
import React from "react";
import Symbols from "components/symbols/Symbols";
import { SiteMeta } from "components/meta/Meta";
import StyleReset from "components/styles/reset";
import { PageContent } from "./base.styles";
type PropType = {
  content: React$Node,
  header?: React$Node,
  footer?: React$Node,
  styleResetProps?: any,
};

const Base = (props: PropType) => (
  <div>
    <SiteMeta />
    <StyleReset {...props.styleResetProps} />
    <Symbols />
    {props.header}
    <PageContent>{props.content}</PageContent>
    {props.footer}
  </div>
);

export default Base;
