// @flow
import React from "react";
import Helmet from "react-helmet";
import config from "./config";

export const SiteMeta = () => (
  <Helmet>
    {/* General */}
    <title>{config.title}</title>
    <meta name="description" content={config.description} />
    <meta name="image" content={config.siteImage} />
    {/* Open Graph */}
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={config.title} />
    <meta property="og:description" content={config.description} />
    <meta property="og:url" content={config.siteUrl} />
    <meta property="og:site_name" content={config.siteName} />
    <meta property="og:image" content={config.siteImage} />
    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:description" content={config.description} />
    <meta name="twitter:title" content={config.title} />
    <meta name="twitter:image" content={config.siteImage} />
    {/* Links */}
    <link href={config.siteCSSLink} rel="stylesheet" />
    {/* favicons */}
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicon/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="/favicon/site.webmanifest" />
    <link
      rel="mask-icon"
      href="/favicon/safari-pinned-tab.svg"
      color="#5bbad5"
    />
    <link rel="shortcut icon" href="/favicon/favicon.ico" />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
);

type PropType = {
  title: string,
  url?: string,
  description?: string,
  image?: string,
};

export const PageMeta = (props: PropType) => {
  let { description, image, url } = props;
  return (
    <Helmet>
      {/* General */}
      <title>{props.title + " - " + config.title} </title>
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image} />}
      {/* Open Graph */}
      <meta property="og:title" content={props.title} />
      <meta property="og:url" content={config.siteUrl + (url ? url : "")} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {/* Twitter */}
      <meta name="twitter:title" content={props.title} />
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};
