// @flow

import styled, { css } from "styled-components";
import { spacing, mediaQueries, breakPoints } from "./variables";
import type { ColPropType, RowPropType } from "./layout.types";

/**
 *
 * Container
 *
 */

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${breakPoints.lg}px;
  position: relative;
  padding-right: ${spacing.base}px;
  padding-left: ${spacing.base}px;

  ${mediaQueries.smMin`
    padding-right: ${spacing.xlarge}px;
    padding-left: ${spacing.xlarge}px;
  `}
`;

/**
 *
 * Row
 *
 */

const RowStart = css`
  justify-content: flex-start;
  text-align: start;
`;
const RowCenter = css`
  justify-content: center;
  text-align: center;
`;
const RowEnd = css`
  justify-content: flex-end;
  text-align: end;
`;
const RowTop = css`
  align-items: flex-start;
`;
const RowMiddle = css`
  align-items: center;
`;
const RowBottom = css`
  align-items: flex-end;
`;
const RowAround = css`
  justify-content: space-around;
`;
const RowBetween = css`
  justify-content: space-between;
`;

const RowDirectionReverse = css`
  flex-direction: row-reverse;
`;

const RowDirectionReverseSm = mediaQueries.smMin`
  flex-direction: row-reverse;
`;

const RowStyles = {
  top: RowTop,
  middle: RowMiddle,
  bottom: RowBottom,
  around: RowAround,
  between: RowBetween,
  start: RowStart,
  center: RowCenter,
  end: RowEnd,
  reverse: RowDirectionReverse,
  reverseSm: RowDirectionReverseSm,
};

const RowProps = (props: RowPropType) => {
  let styles = [];
  // Create Styles
  Object.keys(props).map(key => {
    if (RowStyles[key]) {
      styles.push(RowStyles[key]);
    }
  });
  // Join inner arrays
  styles = styles.map(style => style.join(""));
  // Output final string
  return styles.join("");
};

export const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -${spacing.base / 2}px;
  margin-left: -${spacing.base / 2}px;

  ${mediaQueries.smMin`
    margin-right: -${spacing.xlarge / 2}px;
    margin-left: -${spacing.xlarge / 2}px;
  `}

  ${props => RowProps(props)}
`;

/**
 *
 * Columns
 *
 */

const Col1 = css`
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
`;

const Col2 = css`
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
`;

const Col3 = css`
  flex-basis: 25%;
  max-width: 25%;
`;

const Col4 = css`
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
`;

const Col5 = css`
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
`;

const Col6 = css`
  flex-basis: 50%;
  max-width: 50%;
`;

const Col7 = css`
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
`;

const Col8 = css`
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
`;

const Col9 = css`
  flex-basis: 75%;
  max-width: 75%;
`;

const Col10 = css`
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
`;

const Col11 = css`
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
`;

const Col12 = css`
  flex-basis: 100%;
  max-width: 100%;
`;

const ColOff1 = css`
  margin-left: 8.33333333%;
`;

const ColOff2 = css`
  margin-left: 16.66666667%;
`;

const ColOff3 = css`
  margin-left: 25%;
`;

const ColOff4 = css`
  margin-left: 33.33333333%;
`;

const ColOff5 = css`
  margin-left: 41.66666667%;
`;

const ColOff6 = css`
  margin-left: 50%;
`;

const ColOff7 = css`
  margin-left: 58.33333333%;
`;

const ColOff8 = css`
  margin-left: 66.66666667%;
`;

const ColOff9 = css`
  margin-left: 75%;
`;

const ColOff10 = css`
  margin-left: 83.33333333%;
`;

const ColOff11 = css`
  margin-left: 91.66666667%;
`;

const ColStyles = {
  xs1: mediaQueries.xsOnly(Col1),
  xs2: mediaQueries.xsOnly(Col2),
  xs3: mediaQueries.xsOnly(Col3),
  xs4: mediaQueries.xsOnly(Col4),
  xs5: mediaQueries.xsOnly(Col5),
  xs6: mediaQueries.xsOnly(Col6),
  xs7: mediaQueries.xsOnly(Col7),
  xs8: mediaQueries.xsOnly(Col8),
  xs9: mediaQueries.xsOnly(Col9),
  xs10: mediaQueries.xsOnly(Col10),
  xs11: mediaQueries.xsOnly(Col11),
  xs12: mediaQueries.xsOnly(Col12),
  xs_off1: mediaQueries.xsOnly(ColOff1),
  xs_off2: mediaQueries.xsOnly(ColOff2),
  xs_off3: mediaQueries.xsOnly(ColOff3),
  xs_off4: mediaQueries.xsOnly(ColOff4),
  xs_off5: mediaQueries.xsOnly(ColOff5),
  xs_off6: mediaQueries.xsOnly(ColOff6),
  xs_off7: mediaQueries.xsOnly(ColOff7),
  xs_off8: mediaQueries.xsOnly(ColOff8),
  xs_off9: mediaQueries.xsOnly(ColOff9),
  xs_off10: mediaQueries.xsOnly(ColOff10),
  xs_off11: mediaQueries.xsOnly(ColOff11),
  sm1: mediaQueries.smMin(Col1),
  sm2: mediaQueries.smMin(Col2),
  sm3: mediaQueries.smMin(Col3),
  sm4: mediaQueries.smMin(Col4),
  sm5: mediaQueries.smMin(Col5),
  sm6: mediaQueries.smMin(Col6),
  sm7: mediaQueries.smMin(Col7),
  sm8: mediaQueries.smMin(Col8),
  sm9: mediaQueries.smMin(Col9),
  sm10: mediaQueries.smMin(Col10),
  sm11: mediaQueries.smMin(Col11),
  sm12: mediaQueries.smMin(Col12),
  sm_off1: mediaQueries.smMin(ColOff1),
  sm_off2: mediaQueries.smMin(ColOff2),
  sm_off3: mediaQueries.smMin(ColOff3),
  sm_off4: mediaQueries.smMin(ColOff4),
  sm_off5: mediaQueries.smMin(ColOff5),
  sm_off6: mediaQueries.smMin(ColOff6),
  sm_off7: mediaQueries.smMin(ColOff7),
  sm_off8: mediaQueries.smMin(ColOff8),
  sm_off9: mediaQueries.smMin(ColOff9),
  sm_off10: mediaQueries.smMin(ColOff10),
  sm_off11: mediaQueries.smMin(ColOff11),
  md1: mediaQueries.mdMin(Col1),
  md2: mediaQueries.mdMin(Col2),
  md3: mediaQueries.mdMin(Col3),
  md4: mediaQueries.mdMin(Col4),
  md5: mediaQueries.mdMin(Col5),
  md6: mediaQueries.mdMin(Col6),
  md7: mediaQueries.mdMin(Col7),
  md8: mediaQueries.mdMin(Col8),
  md9: mediaQueries.mdMin(Col9),
  md10: mediaQueries.mdMin(Col10),
  md11: mediaQueries.mdMin(Col11),
  md12: mediaQueries.mdMin(Col12),
  md_off1: mediaQueries.mdMin(ColOff1),
  md_off2: mediaQueries.mdMin(ColOff2),
  md_off3: mediaQueries.mdMin(ColOff3),
  md_off4: mediaQueries.mdMin(ColOff4),
  md_off5: mediaQueries.mdMin(ColOff5),
  md_off6: mediaQueries.mdMin(ColOff6),
  md_off7: mediaQueries.mdMin(ColOff7),
  md_off8: mediaQueries.mdMin(ColOff8),
  md_off9: mediaQueries.mdMin(ColOff9),
  md_off10: mediaQueries.mdMin(ColOff10),
  md_off11: mediaQueries.mdMin(ColOff11),
  lg1: mediaQueries.lgOnly(Col1),
  lg2: mediaQueries.lgOnly(Col2),
  lg3: mediaQueries.lgOnly(Col3),
  lg4: mediaQueries.lgOnly(Col4),
  lg5: mediaQueries.lgOnly(Col5),
  lg6: mediaQueries.lgOnly(Col6),
  lg7: mediaQueries.lgOnly(Col7),
  lg8: mediaQueries.lgOnly(Col8),
  lg9: mediaQueries.lgOnly(Col9),
  lg10: mediaQueries.lgOnly(Col10),
  lg11: mediaQueries.lgOnly(Col11),
  lg12: mediaQueries.lgOnly(Col12),
  lg_off1: mediaQueries.lgOnly(ColOff1),
  lg_off2: mediaQueries.lgOnly(ColOff2),
  lg_off3: mediaQueries.lgOnly(ColOff3),
  lg_off4: mediaQueries.lgOnly(ColOff4),
  lg_off5: mediaQueries.lgOnly(ColOff5),
  lg_off6: mediaQueries.lgOnly(ColOff6),
  lg_off7: mediaQueries.lgOnly(ColOff7),
  lg_off8: mediaQueries.lgOnly(ColOff8),
  lg_off9: mediaQueries.lgOnly(ColOff9),
  lg_off10: mediaQueries.lgOnly(ColOff10),
  lg_off11: mediaQueries.lgOnly(ColOff11),
};

const ColProps = (props: ColPropType) => {
  let styles = [];
  // Create Styles
  Object.keys(props).map(key => {
    if (ColStyles[key]) {
      styles.push(ColStyles[key]);
    }
  });
  // Join inner arrays
  styles = styles.map(style => style.join(""));
  // Output final string
  return styles.join("");
};

export const Col = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 100%;
  padding-right: ${spacing.base / 2}px;
  padding-left: ${spacing.base / 2}px;

  ${mediaQueries.smMin`
    padding-right: ${spacing.xlarge / 2}px;
    padding-left: ${spacing.xlarge / 2}px;
  `}
  ${props => ColProps(props)}
`;
