// @flow
// Svg Symbols: <Symbols className="svg is-hidden" />
import styled from "styled-components";
import Symbols from "-!svg-react-loader!../../assets/symbols/symbols.svg";

const HiddenSymbols = styled(Symbols)`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 0;
`;

export default HiddenSymbols;
