// @flow
import React from "react";
import styled from "styled-components";

type PropsType = {
  id: string,
  fill?: string,
  maxWidth?: number,
  maxHeight?: number,
  style?: any,
};

const SVGStyle = styled.svg(({ fill, maxHeight, maxWidth }: PropsType) => ({
  fill: fill ? fill : "#fff",
  display: "inline-block",
  verticalAlign: "middle",
  maxWidth: maxWidth || "100%",
  maxHeight: maxHeight || "100%",
}));

const Symbol = (props: PropsType) => {
  let { id, style } = props;

  return (
    <SVGStyle {...props} {...style} xmlnsXlink="http://www.w3.org/1999/xlink">
      <use xlinkHref={"#symbols-" + id} />
    </SVGStyle>
  );
};

export default Symbol;
